var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("page-header", { attrs: { title: "Отделка" } }, [
        _vm._v(
          " Апартаменты с отделкой – вариант для сбалансированной жизни. Выбрав стиль Optima или Business, вы сможете воплотить любое интерьерное решение. Полная готовность стен, встроенные светильники брендов Lightstar и Novotech, межкомнатные двери высотой от 2-х до 2.4 м, оборудованная сантехникой от ведущих производителей ванная комната – предусмотрены в обоих вариантах отделки. При этом акцентный черный и нейтральный белый цвета элементов декора внесут в интерьер особый шарм. Варьируя комбинациями «вечных и умных» цветов, мы создадим особенный – интеллектуальный – стиль ваших апартаментов. "
        ),
      ]),
      _c("features-block"),
      _c("best-apartments-block"),
      _c("callback-block", {
        attrs: { id: "callback", "id-formblock": "question-block__callback" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }