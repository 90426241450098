<template>
  <div>
    <page-header title="Отделка">
      Апартаменты с отделкой – вариант для сбалансированной жизни. Выбрав стиль
      Optima или Business, вы сможете воплотить любое интерьерное решение.
      Полная готовность стен, встроенные светильники брендов Lightstar и
      Novotech, межкомнатные двери высотой от 2-х до 2.4 м, оборудованная
      сантехникой от ведущих производителей ванная комната – предусмотрены в
      обоих вариантах отделки. При этом акцентный черный и нейтральный белый
      цвета элементов декора внесут в интерьер особый шарм. Варьируя
      комбинациями «вечных и умных» цветов, мы создадим особенный –
      интеллектуальный – стиль ваших апартаментов.
    </page-header>
    <features-block />
    <best-apartments-block />
    <callback-block id="callback" id-formblock="question-block__callback" />
  </div>
</template>

<script>
import PageHeader from "@/components/elements/PageHeader";
import FeaturesBlock from "@/components/blocks/FeaturesBlock";
import CallbackBlock from "@/components/blocks/CallbackBlock";
import BestApartmentsBlock from "@/components/blocks/BestApartmentsBlock";

export default {
  name: "ApartmentDecorationPage",
  components: {
    BestApartmentsBlock,
    CallbackBlock,
    FeaturesBlock,
    PageHeader,
  },
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Интерьеры с отделкой. Материалы от брендов Kerama Marazzi, Caparol, Kronotex.",
      },
    ],
  },
  data: () => ({
    activeTab: "1",
  }),
};
</script>

<style scoped lang="scss">
.page-header {
  background-color: #231f20;
  color: #ffffff;
  padding-bottom: 30px;

  &__top {
    display: flex;
    align-items: center;

    &-panel {
      margin-left: 40px;
    }
  }

  &__bottom {
    font-family: $font-family-rubik;
    font-weight: 300;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.03em;
    color: #ffffff;
    opacity: 0.5;
  }
}
</style>
